.main-container{
    display: grid;
    grid-template-columns: repeat(5, minmax(100px, 1fr));
    grid-template-rows: repeat(2, 161px);
    grid-gap: 20px;
}

.card-1{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.card-2{
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.card-3{
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}

.card-4{
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.card-5{
    /* grid */
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

.card-6{
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}

.card-7{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff661b !important;
    color: white;
    grid-column: 4 / 5;
    grid-row: 1 / 3;
}

.card-8{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    grid-column: 5 / 6;
    grid-row: 1 / 3;
}

.content-info{
    text-align: center;
    margin: 40px;
}

@media screen and (max-width: 1643px) {
    .main-container{
        grid-template-columns: repeat(6, minmax(100px, 1fr));
        grid-template-rows: repeat(6, 80px);
    }

    .card-1{
        grid-column: 1 / 3;
        grid-row: 1 / 3;
    }


    .card-2{
        grid-column: 3 / 5;
        grid-row: 1 / 3;
    }


    .card-3{
        grid-column: 1 / 3;
        grid-row: 3 / 5;
    }
    

    .card-4{
        grid-column: 3 / 5;
        grid-row: 3 / 5;
    }


    .card-5{
    
        grid-column: 1 / 3;
        grid-row: 5 / 7;
    }


    .card-6{
        grid-column: 3 / 5;
        grid-row: 5 / 7;
    }


    .card-7{
        grid-column: 5 / 7;
        grid-row: 1 / 4;
    }


    .card-8{
        grid-column: 5 / 7;
        grid-row: 4 / 7;
}
}

@media screen and (max-width: 1024px) {
    .main-container{
        display: grid;
        grid-template-columns: repeat(1, minmax(100px, 1fr));
        grid-template-rows: repeat(8,  161px);
        grid-gap: 20px;
    }

    .card-1{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }


    .card-2{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }


    .card-3{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }


    .card-4{
        grid-column: 1 / 2;
        grid-row: 4 / 5;
    }


    .card-5{
    
        grid-column: 1 / 2;
        grid-row: 5 / 6;
    }


    .card-6{
        grid-column: 1 / 2;
        grid-row: 6 / 7;
    }


    .card-7{
        grid-column: 1 / 2;
        grid-row: 7 / 9;
    }

    
    .card-8{
        grid-column: 1 / 2;
        grid-row: 9 / 11;
    }
}

.dashboard-container{
    display: grid;
    grid-template-columns: repeat(12, minmax(40px, 1fr));
    grid-template-rows: repeat(2, 400px);
    grid-gap: 20px;
}

.dashboard-card-1{
    grid-column: 1 / 7;
    grid-row: 1 / 2;
}

.dashboard-card-2{
    display: flex;
    justify-content: center;
    padding: 10px;
    grid-column: 10 / 13;
    grid-row: 1 / 2;
}

.dashboard-card-2-modify{
    display: flex;
    justify-content: center;
    padding: 10px;
    grid-column: 7 / 13;
    grid-row: 1 / 2;
}

.dashboard-card-3{
    grid-column: 1 / 8;
    grid-row: 2 / 3;
}

.dashboard-card-4{
    grid-column: 8 / 13;
    grid-row: 2 / 3;
}

.dashboard-card-5{
    display: flex;
    justify-content: center;
    grid-column: 7 / 10;
    grid-row: 1 / 2;
}

.dasboard-content{
    height: 100%;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1600px) {
    .dashboard-container{
        grid-template-rows: repeat(3, 400px);
    }
    
    .dashboard-card-1{
        grid-column: 1 / 13;
        grid-row: 1 / 2;
    }

    .dashboard-card-5{
        grid-column: 1 / 7;
        grid-row: 2 / 3;
    }

    .dashboard-card-2{
        display: flex;
        justify-content: center;
        padding: 10px;
        grid-column: 7 / 13;
        grid-row: 2 / 3;
    }

    .dashboard-card-2-modify{
        display: flex;
        justify-content: center;
        padding: 10px;
        grid-column: 1 / 13;
        grid-row: 2 / 3;
    }

    .dashboard-card-3{
        grid-column: 1 / 7;
        grid-row: 3 / 4;
    }

    .dashboard-card-4{
        grid-column: 7 / 13;
        grid-row: 3 / 4;
    }
}

@media screen and (max-width: 1024px) {
    .dashboard-container{
        grid-template-columns: repeat(2, minmax(50px, 1fr));
        grid-template-rows: repeat(5, 400px);
    }

    .dashboard-card-1{
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }

    .dashboard-card-5{
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }

    .dashboard-card-2{
        display: flex;
        justify-content: center;
        padding: 10px;
        grid-column: 1 / 3;
        grid-row: 3 / 4;
    }

    .dashboard-card-2-modify{
        display: flex;
        justify-content: center;
        padding: 10px;
        grid-column: 1 / 3;
        grid-row: 2 / 4;
    }

    .dashboard-card-3{
        grid-column: 1 / 3;
        grid-row: 4 / 5;
    }

    .dashboard-card-4{
        grid-column: 1 / 3;
        grid-row: 5 / 6;
    }

}