$color-title: #721c24;
$color-navbar: #006FBD;

$primary: #007bff;

:root {
    --yellow-color: #ffb300;
    --orange-color: #ff6300;
    --red-color: #e10036;
    --green-color: #6ac400;
    --grey-color: #f4f4f4;
}
