.section_info {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.backgroun-red{
    background-color: #FFD2D2;
    color: #B80000;
}