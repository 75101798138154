.background-qr{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100vw;
    min-height: 100vh;
    background-color: #202020 !important;
}

.background-qr div {
    box-shadow: none !important;
}

.qr-container{
    padding: 25px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='8' stroke-dasharray='150' stroke-dashoffset='77' stroke-linecap='square'/%3e%3c/svg%3e");
}

.modal-title{
    width: 100%;
}