/* Style the sidenav links and the dropdown button */
.dropdown-show,
.dropdown-btn,
.dropdown-btn-reporting {
    padding: 6px 8px 6px 16px;
    color: #818181;
    display: block;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    font-weight: bold;
    justify-content: center;
}

/* On mouse-over */
.dropdown-show:hover,
.dropdown-btn:hover,
.dropdown-btn-reporting:hover {
    color: #0b43c8;
    outline: none;
    text-decoration: none;
}

.active-a {
    color: #0b43c8 !important;
    list-style-type: circle;
}

.active-a::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    margin-right: 10px;
    background-color: #0b43c8;
}

/* Add an active class to the active dropdown button */
.active {
    color: black;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
    display: none;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
    float: right;
}
