.navbar-menu{
    z-index: 1;
    background-color:white;
}

.navbar-menu-main{
    display: grid;
    grid-template-columns: repeat(12, minmax(10px, 1fr));
    grid-template-rows: repeat(1, 80px);
    grid-gap: 20px;
}

.navbar-card-1{
    /* background-color: blue; */
    grid-column: 1 / 4;
    grid-row: 1 / 2;
}

.navbar-card-2{
    /* background-color: blue; */
    padding-right: 20px;
    grid-column: 10 / 12;
    grid-row: 1 / 2;
}


.navbar-card-4{
    /* background-color: blue; */
    margin: auto;
    grid-column: 12 / 13;
    grid-row: 1 / 2;
}

.navbar-card-3{
    /* background-color: blue; */
    grid-column: 13 / 14;
    grid-row: 1 / 2;
}

.navbar-card-burger-1{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.navbar-card-burger-2{
    grid-column: 2 / 4;
    grid-row: 1 / 2;
}


@media screen and (max-width: 768px) {
    .navbar-menu-main{
        display: grid;
        grid-template-columns: repeat(3, minmax(50px, 1fr));
        grid-template-rows: repeat(1, 60px);
        grid-gap: 5px;
    }

    .navbar-card-1{
        /* background-color: blue; */
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }

    .navbar-card-2{
        /* background-color: blue; */
        grid-column: 2 / 3;
        grid-row: 1 / 3;
    }

    .navbar-card-3{
        /* background-color: blue; */
        grid-column: 3 / 4;
        grid-row: 1 / 2;
    }

    .navbar-card-4{
        /* background-color: blue; */
        position: fixed;
        bottom: 15px;
        right: 15px;
        z-index: 1;
    }

    .actions{
        margin-left: 4px;
        margin-bottom: 6px;
        /* display: flex;
        justify-content: center;
        align-items: center; */
    }
}