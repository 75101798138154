.custom-background {
    background-image: url('../../../../../assets/img/firefighter.png');
    background-position: center;
    background-size: cover;
}
.card-login {
    min-height: 80%;
    min-width: 70%;
}
@media only screen and (max-width: 767px) {
    .imgLogo-login img {
        max-width: 160px;
    }
}
@media only screen and (min-width: 768px) {
    .imgLogo-login {
        border-right: 2px solid #4865D3;
    }
    .imgLogo-login img {
        max-width: 300px;
    }
}
@media only screen and (min-width: 1500px){
    .card-login {
        min-height: 60%;
        min-width: 50%;
    }
}