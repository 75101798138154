.binnacle-edit {
    background-color: #ffb3908a;
    margin: 5px 0;
    padding: 3px 0;
}

.txt-black {
    color: #000;
}

.txt-red {
    color: #ca3604;
}
